import router from '@/router';
import { ListHeaderItem, SearchKey, RequestData } from '@/components/common/list';

function getMenus(basicRouter: string) {
    return [{
        label: WordList.ProperAllCommunity,
        key: 'community',
        path: `/${basicRouter}/communityDevice?type=community`
    }, {
        label: WordList.ProperAllOffice,
        key: 'office',
        path: `/${basicRouter}/communityDevice?type=office`
    }];
}

const changePath = (key: string, path: string) => {
    router.push(path);
};

type ProjectDataType = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        searchKeyList: SearchKey;
        listRequestData: RequestData;
    }
}
const getProjectData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Project',
        label: WordList.ProperAllCommunity
    }, {
        name: 'Installer',
        label: WordList.NavInHtmlCommunity
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType,
        type: 'customize'
    }, {
        name: 'IPAddress',
        label: WordList.TabelDeviceInHtmlIPAddress
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'LastConnected',
        label: WordList.DeviceListTanleLastConnected
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Project',
        label: WordList.ProperAllOffice
    }, {
        name: 'Installer',
        label: WordList.ProperAllTextOfficeManager
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType,
        type: 'customize'
    }, {
        name: 'IPAddress',
        label: WordList.TabelDeviceInHtmlIPAddress
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'LastConnected',
        label: WordList.DeviceListTanleLastConnected
    }];
    const communitySearchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }, {
        label: WordList.NavInHtmlCommunity,
        name: 'Installer'
    }, {
        label: WordList.ProperAllCommunity,
        name: 'Project'
    }];
    const officeSearchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.ProperAllOffice,
        name: 'Project'
    }, {
        label: WordList.ProperAllTextOfficeManager,
        name: 'Installer'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }];
    const communityRequestData: RequestData = {
        url: 'v3/web/community/device/getList',
        param: {
            searchKey: 'MAC',
            searchValue: '',
            type: 0
        }
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/device/getList',
        param: {
            searchKey: 'MAC',
            searchValue: '',
            type: 1
        }
    };
    const projectData: ProjectDataType = {
        community: {
            header: communityHeader,
            searchKeyList: communitySearchKeyList,
            listRequestData: communityRequestData
        },
        office: {
            header: officeHeader,
            searchKeyList: officeSearchKeyList,
            listRequestData: officeRequestData
        }
    };
    return projectData;
};

export default null;
export {
    getMenus,
    changePath,
    getProjectData
};
