
import {
    defineComponent, Ref, ref,
    computed,
    PropType,
    watch
} from 'vue';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import router, { distributorSubRouter, distributorBaseRouter } from '@/router';
import {
    listPagination, RequestData, ListHeaderItem, SearchKey
} from '@/components/common/list';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import autopDialog from '@/components/view/common/dialog/autop';
import { getMenus, changePath, getProjectData } from './util';

export default defineComponent({
    components: {
        listPagination,
        deviceIcon,
        deviceStatus,
        secondaryNav,
        autopDialog
    },
    props: {
        type: {
            type: String as PropType<ProjectType>,
            default: 'community'
        }
    },
    setup(props) {
        const secondMenus = getMenus(distributorBaseRouter);
        const activeNavItem = computed(() => props.type);
        const headers: Ref<ListHeaderItem[]> = ref([]);
        const searchKeyList: Ref<SearchKey> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        watch(activeNavItem, () => {
            const projectData = getProjectData()[activeNavItem.value];
            headers.value = projectData.header;
            searchKeyList.value = projectData.searchKeyList;
            listRequestData.value = projectData.listRequestData;
        }, {
            immediate: true
        });

        const updateToList = ref(0);

        const goToDeviceInfo = (item: object) => {
            localStorage.setItem('detail', JSON.stringify(item));
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.communityDeviceDetail}`);
        };

        const isShowAutopDialog = ref(false);
        const autoP = CONFIG_ENV.isOpenAutoP;
        return {
            listRequestData,
            searchKeyList,
            headers,
            updateToList,
            goToDeviceInfo,
            secondMenus,
            activeNavItem,
            changePath,
            isShowAutopDialog,
            autoP
        };
    }
});
